import { createApp } from 'vue';
import YoutubeTrailerThumbnail from '../components/YoutubeTrailerThumbnail';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(
    document.querySelectorAll('.js-youtube-trailer-thumbnail'),
    (youtubeTrailerThumbnail) => {
      const app = createApp({
        data() {
          return {
            alt: '',
            hasThumbnail: false,
            thumbnailPath: ''
          };
        },

        mounted: function () {
          const { dataset } = this.$el.parentElement;
          this.alt = dataset.alt;
          this.thumbnailPath = dataset.youtubeThumbnailPath;
          this.setHasThumbnail();
        },

        methods: {
          setHasThumbnail: function () {
            const src = this.thumbnailPath;

            return new Promise((resolve, reject) => {
              const img = new Image();

              img.src = src;
              img.onload = () => {
                if (img.width === 120 && img.height === 90) {
                  return reject(new Error(img));
                }

                resolve(img);
              };
              img.onerror = reject;
            })
              .then(() => (this.hasThumbnail = true))
              .catch(() => (this.hasThumbnail = false));
          }
        },

        components: {
          YoutubeTrailerThumbnail
        }
      });

      app.use(bugsnagVue).mount(youtubeTrailerThumbnail);
    }
  );
});
