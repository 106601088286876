import { createApp, onMounted } from 'vue';
import { useMark } from '../../composables/useMark';
import MarkEditor from './../components/MarkEditor';
import ModalOverlay from './../components/ModalOverlay';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { useCategory } from '../../composables/useCategory';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-btn-mark'), (markBtn) => {
    const category = useCategory();

    const app = createApp({
      setup() {
        const {
          mark,
          onMarkHookMounted,
          viewingMarkCount,
          changeSpoiler,
          changeShareTwitter,
          closeEditor,
          editorSaveMark,
          editorDeleteMark,
          onClickMarkButton,
          removeMarkData
        } = useMark(category);

        onMounted(() => {
          onMarkHookMounted(markBtn.dataset);
        });

        return {
          mark,
          onMarkHookMounted,
          viewingMarkCount,
          changeSpoiler,
          changeShareTwitter,
          closeEditor,
          editorSaveMark,
          editorDeleteMark,
          onClickMarkButton,
          removeMarkData
        };
      },

      components: {
        MarkEditor,
        ModalOverlay
      }
    });

    app.use(bugsnagVue).mount(markBtn);
  });
});
