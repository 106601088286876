import { createApp } from 'vue';
import eventHub from './../../common/modules/EventHub';
import MarkLikeUserList from './../components/MarkLikeUserList';
import ErrorModal from './../components/ErrorModal';
import errorNotification from './../../utils/ErrorNotification';
import MovieAPI from './../../api_clients/Movie';
import AnimeAPI from './../../api_clients/Anime';
import DramaAPI from './../../api_clients/Drama';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { CATEGORY_TYPE } from '../../constants/CategoryType';
import { useCategory } from '../../composables/useCategory';

const movieAPI = new MovieAPI();
const animeAPI = new AnimeAPI();
const dramaAPI = new DramaAPI();

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-mark-like-user')) {
    const app = createApp({
      data() {
        return {
          movieId: '',
          animeSeriesId: '',
          animeSeasonId: '',
          dramaSeriesId: '',
          dramaSeasonId: '',
          markId: '',
          likeId: '',
          likeCount: 0,
          isUserSignedIn: false,
          isActive: false,
          users: [],
          nextPage: 1,
          isApiProcessing: false,
          categoryApi: null,
          category: null
        };
      },

      created: function () {
        eventHub.$on('fetch-mark-like-users', this.fetchMarkLikeUsers);
        eventHub.$on('save-mark-like', this.saveMarkLike);
        eventHub.$on('delete-mark-like', this.deleteMarkLike);
      },

      mounted: function () {
        this.category = useCategory();
        this.initializeWithCategory(this.category);
      },

      beforeUnmount: function () {
        eventHub.$off('fetch-mark-like-users', this.fetchMarkLikeUsers);
        eventHub.$off('save-mark-like', this.saveMarkLike);
        eventHub.$off('delete-mark-like', this.deleteMarkLike);
      },

      methods: {
        initializeWithCategory(category) {
          const { dataset } = this.$el.parentElement;
          switch (category) {
            case CATEGORY_TYPE.ANIME:
              this.animeSeriesId = dataset.animeSeriesId;
              this.animeSeasonId = dataset.animeSeasonId;
              this.categoryApi = animeAPI;
              break;
            case CATEGORY_TYPE.DRAMA:
              this.dramaSeriesId = dataset.dramaSeriesId;
              this.dramaSeasonId = dataset.dramaSeasonId;
              this.categoryApi = dramaAPI;
              break;
            default:
              this.movieId = dataset.movieId;
              this.categoryApi = movieAPI;
              break;
          }

          this.markId = dataset.markId;
          this.likeId = dataset.likeId;
          this.likeCount = dataset.likeCount;
          this.isUserSignedIn = dataset.isUserSignedIn;
          this.isActive = dataset.isMarked;

          this.fetchMarkLikeUsers();
        },
        toBoolean: function (val) {
          return val === 'true';
        },
        fetchMarkLikeUsers: function () {
          const params = {
            page: this.nextPage
          };
          const apiParams = this.getParams(this.markId, params);

          this.categoryApi
            .fetchMarkLikeUsers(...apiParams)
            .then((response) => {
              this.setMarkLikeUsersData(response.data);
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        saveMarkLike: function () {
          const apiParams = this.getParams(this.markId);

          this.isApiProcessing = true;
          this.categoryApi
            .saveMarkLike(...apiParams)
            .then((response) => {
              this.setMarkLikeData(response.data);
              this.addMarkLikeUserData(response.data.markLike);
              this.isActive = true;
              this.isApiProcessing = false;
            })
            .catch((response) => {
              this.isApiProcessing = false;
              this.$refs.errorModal.showIfDetail(response);
            });
        },
        deleteMarkLike: function () {
          const apiParams = this.getParams(this.markId, this.likeId);

          this.isApiProcessing = true;
          this.categoryApi
            .deleteMarkLike(...apiParams)
            .then((response) => {
              this.setMarkLikeData(response.data);
              this.removeMarkLikeUserData(response.data.markLike);
              this.isActive = false;
              this.isApiProcessing = false;
            })
            .catch((response) => {
              this.isApiProcessing = false;
              errorNotification(response);
            });
        },

        setMarkLikeUsersData: function (data) {
          this.users = this.users.concat(data.users);
          this.nextPage = data.nextPage;
        },

        setMarkLikeData: function (data) {
          this.likeId = data.markLike.id;
          this.likeCount = data.markLike.count;
        },

        addMarkLikeUserData: function (data) {
          this.users.unshift(data.user);
        },

        removeMarkLikeUserData: function (data) {
          this.users.some((user, i) => {
            if (user.id === data.user.id) this.users.splice(i, 1);
          });
        },
        getParams(...args) {
          switch (this.category) {
            case CATEGORY_TYPE.DRAMA:
              return [this.dramaSeriesId, this.dramaSeasonId, ...args];
            case CATEGORY_TYPE.ANIME:
              return [this.animeSeriesId, this.animeSeasonId, ...args];
            case CATEGORY_TYPE.MOVIE:
              return [this.movieId, ...args];
            default:
              return null;
          }
        }
      },

      components: {
        MarkLikeUserList,
        ErrorModal
      }
    });

    app.use(bugsnagVue).mount('#js-mark-like-user');
  }
});
