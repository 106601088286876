import { createApp, onMounted } from 'vue';
import { useGoogleRelation } from '../../composables/useGoogleRelation';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  const googleRelationEl = document.getElementById('js-google-relation');
  if (googleRelationEl) {
    const app = createApp({
      setup() {
        const { isRelated, onGoogleRelationHookMounted, createToken, deleteToken, onClickRelation } =
          useGoogleRelation();

        onMounted(() => {
          onGoogleRelationHookMounted(googleRelationEl.dataset);
        });

        return {
          isRelated,
          onGoogleRelationHookMounted,
          createToken,
          deleteToken,
          onClickRelation
        };
      }
    });

    app.use(bugsnagVue).mount('#js-google-relation');
  }
});
