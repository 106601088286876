import Base from './Base';
import Qs from 'qs';
import * as url from './../constants/URL';
import * as window_name from './../constants/WindowName';

export default class SNS extends Base {
  constructor() {
    super();
  }

  // SNS情報 Facebook認証
  createFacebookToken() {
    this.post(url.OMNIAUTH_FACEBOOK);
  }

  // SNS情報 Twitter認証
  createTwitterToken() {
    this.post(url.OMNIAUTH_TWITTER);
  }

  // SNS情報 Apple認証
  createAppleToken() {
    this.post(url.OMNIAUTH_APPLE);
  }

  // SNS情報 Google認証
  createGoogleToken() {
    this.post(url.OMNIAUTH_GOOGLE);
  }

  createTwitterTokenWithPopup() {
    this.open(url.OMNIAUTH_TWITTER_WINDOW, window_name.OMNIAUTH_TWITTER_WINDOW);
  }

  // SNS情報 Facebookトークン削除
  deleteFacebookToken(params) {
    return this.client.delete('/sns/facebook/token', params);
  }

  // SNS情報 Twitterトークン削除
  deleteTwitterToken(params) {
    return this.client.delete('/sns/twitter/token', params);
  }

  // SNS情報 Appleトークン削除
  deleteAppleToken(params) {
    return this.client.delete('/sns/apple/token', params);
  }

  // SNS情報 Googleトークン削除
  deleteGoogleToken(params) {
    return this.client.delete('/sns/google/token', params);
  }

  // SNS情報 Twitter Share Clip更新
  updateTwitterClipShare(params) {
    return this.client.patch('/sns/twitter/clip_share', params);
  }

  post(action) {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = action;

    this.verify_authenticity_token(form);
    document.body.append(form);
    form.submit();
  }

  open(action, target, features) {
    window.open('', target, features);

    const form = document.createElement('form');
    form.method = 'post';
    form.action = action;
    form.target = target;

    this.verify_authenticity_token(form);
    document.body.append(form);
    form.submit();
  }

  verify_authenticity_token(form) {
    const token = document.createElement('input');
    token.type = 'hidden';
    token.name = 'authenticity_token';
    token.value = document.getElementsByName('csrf-token')[0].content;

    form.appendChild(token);
  }
}
