import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import MovieAPI from '../api_clients/Movie';
import DramaAPI from '../api_clients/Drama';
import AnimeAPI from '../api_clients/Anime';
import errorNotification from '../utils/ErrorNotification';
import * as message from '../constants/Message';
import * as markConstants from '../constants/Mark';
import hyphenNumber from '../utils/HyphenNumber';
import { ref, computed } from 'vue';
import isLocalStorageSupported from '../utils/IsLocalStorageSupported';
import { CATEGORY_TYPE } from '../constants/CategoryType';

const movieAPI = new MovieAPI();
const dramaAPI = new DramaAPI();
const animeAPI = new AnimeAPI();

const CALL_BACK_TYPE = {
  closeEditor: 'closeEditor',
  changeSpoiler: 'changeSpoiler',
  changeShareTwitter: 'changeShareTwitter',
  editorSaveMark: 'editorSaveMark',
  editorDeleteMark: 'editorDeleteMark',
  saveMark: 'saveMark',
  updateMark: 'updateMark',
  deleteMark: 'deleteMark'
};

export const useMark = (type) => {
  const mark = ref({
    id: 0,
    movieId: null,
    referenceMarkId: null,
    animeSeriesId: null,
    animeSeasonId: null,
    dramaSeriesId: null,
    dramaSeasonId: null,
    review: '',
    score: 0.0,
    isSpoiler: false,
    createdAt: null,
    count: 0,
    tags: [],
    userId: null,
    viewingRecords: [],
    isTwitterShare: '0',
    social: {
      twitter: false
    },
    isActive: false,
    show: false,
    isApiProcessing: false
  });
  const isRecommend = ref(false);
  const recommendCarouselElement = ref(null);
  const recommendCarouselStyle = ref(null);
  // anime, drama
  const isSlider = ref(false);
  const carouselElement = ref(null);
  const carouselStyle = ref(null);

  const onMarkHookMounted = (dataset) => {
    const { dataReferenceMarkId, isRecommend: dataIsRecommend } = dataset;
    const dataMark = JSON.parse(dataset.mark || dataset.animeSeasonMark || dataset.dramaSeasonMark);
    //anime
    const { isSlider: isSliderData } = dataset;

    const socialValue =
      !_isNull(dataMark.social) && !_isUndefined(dataMark.social)
        ? { twitter: dataMark.social.TwitterProfile === 'true' }
        : { twitter: false };

    mark.value = {
      ...mark.value,
      id: dataMark.id,
      movieId: dataMark.movie_id,
      animeSeriesId: dataMark.anime_series_id,
      animeSeasonId: dataMark.anime_season_id,
      dramaSeriesId: dataMark.drama_series_id,
      dramaSeasonId: dataMark.drama_season_id,
      count: parseInt(dataMark.count, 10),
      isActive: dataMark.active === 'true',
      social: socialValue,
      isTwitterShare: isTwitterShare(),
      referenceMarkId: dataReferenceMarkId
    };

    isRecommend.value = dataIsRecommend === 'true';
    // anime
    isSlider.value = isSliderData === 'true';
  };

  const isTwitterShare = () => {
    if (!isLocalStorageSupported()) {
      return '0';
    }
    return localStorage.getItem('is_twitter_share') === 'true' ? '1' : '0';
  };

  const viewingMarkCount = computed(() => {
    return hyphenNumber(mark.value.count);
  });

  const closeEditor = () => {
    // レコメンドのスライド内の場合、保存しておいたSwiperのtransform style属性を再設定
    // (transform, fixedの問題対策)
    if (recommendCarouselElement.value) {
      recommendCarouselElement.value.style.transform = recommendCarouselStyle.value;
    }

    // anime
    if (carouselElement.value) {
      carouselElement.value.style.transform = carouselStyle.value;
    }

    mark.value.show = false;
  };

  const changeSpoiler = () => {
    mark.value.isSpoiler = !mark.value.isSpoiler;
  };

  const changeShareTwitter = () => {
    if (!isLocalStorageSupported()) {
      return;
    }

    mark.value.isTwitterShare = mark.value.isTwitterShare === '0' ? '1' : '0';
    if (mark.value.isTwitterShare == '1') {
      localStorage.setItem('is_twitter_share', 'true');
    } else {
      localStorage.removeItem('is_twitter_share');
    }
  };

  const editorSaveMark = (data) => {
    if (checkInputData(data)) {
      if (mark.value.isActive) {
        updateMark();
      } else {
        saveMark();
      }
    }
  };

  const editorDeleteMark = () => {
    if (window.confirm(message.DELETE_REVIEW_CONFIRM)) {
      deleteMark();
    }
  };

  const onClickMarkButton = () => {
    // レコメンドのスライド内の場合、Swiperのtransform style属性を一旦保存して、styleから削除
    // (transform, fixedの問題対策)
    if (isRecommend) {
      recommendCarouselElement.value =
        document.querySelector('.p-content-detail__recommend-carousel__wrapper') ||
        document.querySelector('.c2-horizontal-scroll__wrapper');
      if (recommendCarouselElement.value) {
        recommendCarouselStyle.value = recommendCarouselElement.value.style.transform;
        recommendCarouselElement.value.style.transform = '';
      }
    }

    // 既に投稿済みの場合は最初に過去分を読み込み
    if (mark.value.isActive && mark.value.createdAt === null) {
      initialLoad();
    } else {
      if (type === CATEGORY_TYPE.MOVIE) {
        mark.value.isTwitterShare = isTwitterShare();
      }
      mark.value.show = true;
    }
  };

  const initialLoad = () => {
    const params = getParams(type, CALL_BACK_TYPE.fetchMark);
    callWithOptionParams(type, CALL_BACK_TYPE.fetchMark, ...params)
      .then((response) => {
        setMarkData(response.data, true);
      })
      .catch((response) => {
        errorNotification(response);
      });
  };

  const saveMark = () => {
    mark.value.isApiProcessing = true;
    const params = getParams(type, CALL_BACK_TYPE.saveMark);
    callWithOptionParams(type, CALL_BACK_TYPE.saveMark, ...params)
      .then((response) => {
        window.location.reload();
        mark.value.isApiProcessing = false;
      })
      .catch((response) => {
        errorNotification(response);
        mark.value.isApiProcessing = false;
      });
  };

  const updateMark = () => {
    mark.value.isApiProcessing = true;
    const params = getParams(type, CALL_BACK_TYPE.updateMark);
    callWithOptionParams(type, CALL_BACK_TYPE.saveMark, ...params)
      .then((response) => {
        window.location.reload();
        mark.value.isApiProcessing = false;
      })
      .catch((response) => {
        errorNotification(response);
        mark.value.isApiProcessing = false;
      });
  };

  const deleteMark = () => {
    mark.value.isApiProcessing = true;
    const params = getParams(type, CALL_BACK_TYPE.deleteMark);
    callWithOptionParams(type, CALL_BACK_TYPE.deleteMark, ...params)
      .then((response) => {
        window.alert(message.DELETE_REVIEW_COMPLETE);
        mark.value.isApiProcessing = false;
        redirectByProduct(type);
      })
      .catch((response) => {
        errorNotification(response);
        mark.value.isApiProcessing = false;
      });
  };

  const redirectByProduct = (type) => {
    //レビュー詳細ページであれば、ページ自体がなくなるので映画詳細ページへ飛ぶ。
    if (
      type === CATEGORY_TYPE.MOVIE &&
      window.location.href.indexOf('/movies/' + mark.value.movieId + '/reviews/' + mark.value.id) >= 0
    ) {
      return (window.location.href = '/movies/' + mark.value.movieId);
    }

    if (
      type === CATEGORY_TYPE.ANIME &&
      window.location.href.indexOf(
        `/animes/${mark.value.animeSeriesId}/${mark.value.animeSeasonId}/reviews/${mark.value.id}`
      ) >= 0
    ) {
      return (window.location.href = `/animes/${mark.value.animeSeriesId}/${mark.value.animeSeasonId}`);
    }

    if (
      type === CATEGORY_TYPE.DRAMA &&
      window.location.href.indexOf(
        `/dramas/${mark.value.dramaSeriesId}/${mark.value.dramaSeasonId}/reviews/${mark.value.id}`
      ) >= 0
    ) {
      return (window.location.href = `/dramas/${mark.value.dramaSeriesId}/${mark.value.dramaSeasonId}`);
    }

    window.location.reload();
  };

  const callWithOptionParams = (type, callbackType, ...args) => {
    const productAPI = getProductAPI(type);
    if (callbackType === CALL_BACK_TYPE.fetchMark) {
      return productAPI.fetchMark(...args);
    } else if (callbackType === CALL_BACK_TYPE.saveMark) {
      return productAPI.saveMark(...args);
    } else if (callbackType === CALL_BACK_TYPE.updateMark) {
      return productAPI.updateMark(...args);
    } else if (callbackType === CALL_BACK_TYPE.deleteMark) {
      return productAPI.deleteMark(...args);
    }
  };

  const getProductAPI = (productType) => {
    switch (productType) {
      case CATEGORY_TYPE.DRAMA:
        return dramaAPI;
      case CATEGORY_TYPE.ANIME:
        return animeAPI;
      case CATEGORY_TYPE.MOVIE:
        return movieAPI;
      default:
        return null;
    }
  };

  const getParams = (productType, callbackType) => {
    let markData = null;
    if (callbackType === CALL_BACK_TYPE.fetchMark || callbackType === CALL_BACK_TYPE.deleteMark) {
      markData = mark.value.id;
    } else {
      markData = mark.value;
    }
    switch (productType) {
      case CATEGORY_TYPE.DRAMA:
        return [mark.value.dramaSeriesId, mark.value.dramaSeasonId, markData];
      case CATEGORY_TYPE.ANIME:
        return [mark.value.animeSeriesId, mark.value.animeSeasonId, markData];
      case CATEGORY_TYPE.MOVIE:
        return [mark.value.movieId, markData];
      default:
        return null;
    }
  };

  const setMarkData = (data, show) => {
    if (show == null) {
      show = false;
    }
    const tags = data.mark.tags.map((elm) => {
      return elm.name;
    });
    mark.value = {
      ...mark.value,
      ...data.mark, // id, movieId, review, score, isSpoiler, createdAt, userId, viewingRecords
      count: parseInt(data.mark.count, 10),
      tags: tags,
      isTwitterShare: isTwitterShare(),
      isActive: true,
      show: show
    };
  };

  const removeMarkData = () => {
    mark.value = {
      ...mark.value,
      id: 0,
      review: '',
      score: 0.0,
      isSpoiler: false,
      createdAt: null,
      tags: [],
      viewingRecords: [],
      isActive: false,
      show: false
    };

    window.alert(message.DELETE_REVIEW_COMPLETE);
  };

  const checkInputData = () => {
    if (mark.value.isSpoiler && !mark.value.review) {
      window.alert(message.PLEASE_INPUT_REVIEW);
      return false;
    }

    if (Array.from(mark.value.review || '').length > markConstants.REVIEW_MAX_LENGTH) {
      window.alert(message.REVIEW_TOO_LONG);
      return false;
    }

    if (mark.value.tags.some((tag) => Array.from(tag).length > markConstants.TAG_MAX_LENGTH)) {
      window.alert(message.TAG_TOO_LONG);
      return false;
    }

    return true;
  };

  return {
    mark,
    onMarkHookMounted,
    viewingMarkCount,
    changeSpoiler,
    changeShareTwitter,
    closeEditor,
    editorSaveMark,
    editorDeleteMark,
    onClickMarkButton,
    removeMarkData
  };
};
