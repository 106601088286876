import MovieAPI from '../api_clients/Movie';
import DramaAPI from '../api_clients/Drama';
import AnimeAPI from '../api_clients/Anime';
import errorNotification from '../utils/ErrorNotification';
import hyphenNumber from '../utils/HyphenNumber';
import { ref, computed } from 'vue';
import { CATEGORY_TYPE } from '../constants/CategoryType';

const movieAPI = new MovieAPI();
const dramaAPI = new DramaAPI();
const animeAPI = new AnimeAPI();

const CALL_BACK_TYPE = {
  saveClip: 'saveClip',
  deleteClip: 'deleteClip'
};

export const useClip = (type) => {
  const clip = ref({
    id: null,
    movieId: null,
    dramaSeriesId: null,
    dramaSeasonId: null,
    animeSeriesId: null,
    animeSeasonId: null,
    referenceMarkId: null,
    count: 0,
    isActive: false,
    isApiProcessing: false
  });

  const onHookMounted = (dataset) => {
    const dataClip = JSON.parse(dataset);

    clip.value = {
      ...clip.value,
      id: dataClip.id,
      movieId: dataClip.movie_id,
      dramaSeriesId: dataClip.drama_series_id,
      dramaSeasonId: dataClip.drama_season_id,
      animeSeriesId: dataClip.anime_series_id,
      animeSeasonId: dataClip.anime_season_id,
      count: parseInt(dataClip.count, 10),
      isActive: dataClip.active === 'true',
      referenceMarkId: dataset.referenceMarkId
    };
  };

  const viewingClipCount = computed(() => {
    return hyphenNumber(clip.value.count);
  });

  const onClickClipButton = () => {
    if (clip.value.isActive) {
      deleteClip();
    } else {
      saveClip();
    }
  };

  const callWithOptionParams = (type, callbackType, ...args) => {
    const productAPI = getProductAPI(type);
    if (callbackType === CALL_BACK_TYPE.saveClip) {
      return productAPI.saveClip(...args);
    }
    if (callbackType === CALL_BACK_TYPE.deleteClip) {
      return productAPI.deleteClip(...args);
    }
  };
  const saveClip = () => {
    const params = getParams(type, CALL_BACK_TYPE.saveClip);
    callWithOptionParams(type, CALL_BACK_TYPE.saveClip, ...params)
      .then((response) => {
        setClipData(response.data);
        clip.value.isActive = true;
        clip.value.isApiProcessing = false;
      })
      .catch((response) => {
        errorNotification(response);
        clip.value.isApiProcessing = false;
      });
  };

  const deleteClip = () => {
    const params = getParams(type, CALL_BACK_TYPE.deleteClip);
    callWithOptionParams(type, CALL_BACK_TYPE.deleteClip, ...params)
      .then((response) => {
        setClipData(response.data);
        clip.value.isActive = false;
        clip.value.isApiProcessing = false;
      })
      .catch((response) => {
        errorNotification(response);
        clip.value.isApiProcessing = false;
      });
  };

  const setClipData = (data) => {
    clip.value = {
      ...clip.value,
      id: data.clip.id,
      count: data.clip.count
    };
  };

  const getProductAPI = (productType) => {
    switch (productType) {
      case CATEGORY_TYPE.DRAMA:
        return dramaAPI;
      case CATEGORY_TYPE.ANIME:
        return animeAPI;
      case CATEGORY_TYPE.MOVIE:
        return movieAPI;
      default:
        return null;
    }
  };

  const getParams = (productType, callbackType) => {
    const clipData = callbackType === CALL_BACK_TYPE.saveClip ? clip.value : clip.value.id;
    switch (productType) {
      case CATEGORY_TYPE.DRAMA:
        return [clip.value.dramaSeriesId, clip.value.dramaSeasonId, clipData];
      case CATEGORY_TYPE.ANIME:
        return [clip.value.animeSeriesId, clip.value.animeSeasonId, clipData];
      case CATEGORY_TYPE.MOVIE:
        return [clip.value.movieId, clipData];
      default:
        return null;
    }
  };

  return {
    clip,
    onHookMounted,
    viewingClipCount,
    onClickClipButton
  };
};
