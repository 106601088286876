import { ref } from 'vue';
import SnsAPI from '../api_clients/SNS';
import errorNotification from '../utils/ErrorNotification';
import * as message from '../constants/Message';
import * as url from '../constants/URL';

const snsAPI = new SnsAPI();

export const useGoogleRelation = () => {
  const isRelated = ref(false);

  const onGoogleRelationHookMounted = (dataset) => {
    isRelated.value = dataset.google_related === 'true';
  };

  const onClickRelation = () => {
    if (!isRelated.value) {
      createToken();
    } else {
      if (window.confirm(message.DELETE_GOOGLE_RELATION_CONFIRM)) {
        deleteToken();
      }
    }
  };

  const createToken = () => {
    snsAPI.createGoogleToken();
  };

  const deleteToken = () => {
    snsAPI
      .deleteGoogleToken()
      .then((response) => {
        if (response.data && response.data.user.loginProhibitedByPassword == true) {
          if (window.confirm(message.SETTING_PASSWORD_CONFIRM)) {
            window.location.href = url.UPDATE_PASSWORD;
          }
        } else {
          isRelated.value = false;
        }
      })
      .catch((response) => {
        errorNotification(response);
      });
  };

  return {
    isRelated,
    onGoogleRelationHookMounted,
    createToken,
    deleteToken,
    onClickRelation
  };
};
