export const PLEASE_INPUT_REVIEW = 'レビューを入力してください。';
export const DELETE_REVIEW_CONFIRM = 'レビューを削除しますか？';
export const DELETE_REVIEW_COMPLETE = 'レビューを削除しました。';
export const REVIEW_TOO_LONG = 'レビューの最大文字数を超えています';
export const TAG_TOO_LONG = '#タグの最大文字数を超えています';
export const DELETE_COMMENT_CONFIRM = 'コメントを削除しますか？';
export const SHOW_SPOILER_CONFIRM = 'ネタバレの内容を表示しますか？';
export const PLEASE_INPUT_COMMENT = 'コメントを入力してください。';
export const DELETE_FACEBOOK_RELATION_CONFIRM = 'Facebook連携を解除しますか？';
export const DELETE_TWITTER_RELATION_CONFIRM = 'Twitter連携を解除しますか？';
export const DELETE_APPLE_RELATION_CONFIRM = 'Apple連携を解除しますか？';
export const DELETE_GOOGLE_RELATION_CONFIRM = 'Google連携を解除しますか？';
export const SETTING_PASSWORD_CONFIRM =
  'パスワード設定が完了していないため、連携を解除することができません。パスワードを設定しますか？';
export const ERROR_COMMUNICATION = '通信が失敗しました。';
export const NETWORK_ERROR = 'インターネットに繋がりませんでした。';
export const TIMEOUT_ERROR = '処理がタイムアウトしました';
